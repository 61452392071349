.my-navbar {
  margin-top: 2%;
  width: 100%;
  z-index: 45 !important;
  color: white !important;
  position: inherit;
}
.ft-size {
  font-size: 12pt !important;
}
.menu {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  z-index: 10 !important;
}
.nav-element {
  padding: 10px;
  margin: 1%;
  text-align: center;
  border-radius: 5px;
  display: inline-flex;
  justify-content: center;
  width: auto;
  color: white;
  z-index: 10 !important;
}
.nav-element:hover {
  padding: 10px;
  margin: 1%;
  border-radius: 5px;
  text-decoration: underline;
  text-decoration-color: white;
  text-decoration-style: ridge;
  text-decoration-line: underline;
  text-underline-offset: 2px;
  text-decoration-thickness: 2px;
  transition-duration: 500ms;

  color: white;
  z-index: 10 !important;
}
.nav-element:active {
  border-radius: 5%;
  background-color: rgba(255, 255, 255, 0.275);
  text-decoration-color: white;
  transition: ease-out 0.4s;
  z-index: 10 !important;
}
.actions-item {
  display: flex;
  width: max-content;
  z-index: 2 !important;
}
.actions-item div {
  text-align: center;
  border-radius: 5px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  text-decoration-color: #5d00ff;
  color: #5d00ff;
  z-index: 2 !important;
}
.elements {
  text-align: center;
  margin: 0 auto;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 80%;
  z-index: 2 !important;
}
.elements-right {
  text-align: center;
  margin: 0 auto;
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 60%;
  z-index: 2 !important;
}
.items {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  z-index: 2 !important;
}
.items-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
  z-index: 2 !important;
}
.elements .nav-element {
  margin: 0;
  padding: 10px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: flex-start;
  width: auto;
  z-index: 2 !important;
}
.elements-right .nav-element {
  margin: 0;
  padding: 5px;
  border-radius: 5px;
  display: inline-flex;
  justify-content: flex-end;
  width: auto;
}
.elements-right .session-button {
  justify-self: flex-end;
  justify-content: space-around;
  height: min-content;
}
.brand {
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
}
.brand-items {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
}
.hide-inPc {
  display: none;
}
.rounded-50 {
  border-radius: 50%;
}
.img-profile-min {
  width: 10%;
  height: 10%;
  border-radius: 50%;
}
.img-profile-min:hover {
  padding: 0%;
  background-color: #5d00ff;
  width: 10%;
  height: 10%;
  border-radius: 50%;
  transition: ease-in-out 0.2s;
  transform: scale(1.1);
}

.img-profile-nav {
  display: inline-flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.img-profile-nav:hover {
  display: inline-flex;
  background-color: #5d00ff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: ease-in-out 0.2s;
  transform: scale(1.1);
}
.logoSvg {
  width: 50px;
  height: 50px;
}
.full-expanded {
  width: 100%;
}
@media screen and (max-width: 1365px) {
  .my-navbar {
    margin-top: 0%;
    z-index: 10 !important;
    background-color: white !important;
  }
  .logo-title {
    color: #5d00ff !important;
  }
  .logoSvg {
    width: 50px;
    height: 50px;
    position: static;
    display: flex;
    align-content: flex-start;
  }
  .menu {
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
    text-align: center;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    align-items: flex-start;
    width: 30%;
    height: 400%;
    z-index: 9 !important;
    background-color: white;
  }
  .blackShadow {
    text-align: center;
    display: block;
    position: fixed;
    top: 0;
    left: 100;
    right: 0;
    align-items: flex-start;
    width: 70%;
    height: 400%;
    background-color: rgba(0, 0, 0, 0.439);
    z-index: 15 !important;
  }
  .items {
    display: block;
    width: 100%;
    z-index: 10 !important;
  }

  .elements {
    position: fixed;
    height: max-content;
    display: block;
    top: 40;
    right: 30;
    width: 30%;
    z-index: 10 !important;
  }
  .menu-icon:active .menu .elements {
    transition: ease-in 2s;
    transform: translateY(10%);
  }
  .full-width {
    width: 100%;
  }
  .show {
    display: block;
  }
  .nav-element {
    padding: 10px;
    margin: 1%;
    text-align: flex-start;
    display: block !important;
    width: 100%;
    color: #5d00ff;
  }
  .nav-element:hover {
    background-color: #5d00ff;
    text-decoration: none;
  }

  .session-button {
    display: block !important;
  }
  .img-profile-nav {
    display: inline-flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .img-profile-nav:hover {
    display: inline-flex;
    background-color: #5d00ff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: ease-in-out 0.2s;
    transform: scale(1.1);
  }
  .profile {
    margin-right: 20%;
    display: block !important;
    justify-content: flex-end;
  }
  .brand-items {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .hide-inPc {
    display: block;
  }
  .hide-inMobile {
    display: none;
  }
  .navbar-toggler-icon {
    display: block !important;
  }
  .menu-icon {
    color: #5d00ff;
    font-size: 25pt !important;
    padding: 30%;
    align-items: center;
    align-self: center;
    display: inline-flex;
    justify-content: center;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }
  .transitionRotateIn {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: all 0.8s ease-in-out;
    transform: rotate(0deg);
  }
  .transitionRotateOut {
    align-items: center;
    align-self: center;
    display: inline-flex;
    transition: ease-in 0.3s;
    transform: scale3d(120%, 150%, 120%);
  }
  .transitionTranslateY {
    margin: 1%;
    transform: skewY(10%);
    transition: all 0.8s ease-in-out;
  }
  .mini-icon {
    width: 1px;
    height: 1px;
  }

  .transitionFadeIn {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
}
@media screen and (max-width: 900px) {
  .my-navbar {
    margin-top: 0%;
    z-index: 0 !important;
    background-color: white !important;
  }
  .img-p {
    width: 100%;
    height: 100%;
  }
  .img-p-large {
    width: 100%;
    height: 100%;
  }
  .img-large {
    width: 100%;
    height: 100%;
  }
  .logo-title {
    justify-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    color: #5d00ff !important;
    width: 100%;
  }
  .logoSvg {
    width: 50px;
    height: 50px;
    position: static;
    display: flex;
    align-content: flex-start;
  }
  .menu {
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4);
    text-align: center;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    align-items: flex-start;
    width: 60%;
    height: 400%;
    background-color: white;
    z-index: 9 !important;
  }
  .blackShadow {
    text-align: center;
    display: block;
    position: fixed;
    top: 0;
    left: 100;
    right: 0;
    align-items: flex-start;
    width: 40%;
    height: 400%;
    background-color: rgba(0, 0, 0, 0.439);
    z-index: 15 !important;
  }
  .items {
    display: block;
    width: 100%;
    z-index: 10 !important;
  }

  .img-profile-nav {
    display: inline-flex;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .img-profile-nav:hover {
    display: inline-flex;
    background-color: #5d00ff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: ease-in-out 0.2s;
    transform: scale(1.1);
  }
  .elements {
    position: fixed;
    height: max-content;
    display: block;
    top: 40;
    right: 30;
    width: 60%;
    z-index: 10 !important;
  }
  .menu-icon:active .menu .elements {
    transition: ease-in 2s;
    transform: translateY(10%);
  }
  .full-width {
    width: 100%;
  }
  .show {
    display: block;
  }
  .nav-element {
    padding: 10px;
    margin: 1%;
    text-align: start;
    display: block !important;
    width: 100%;
    color: #5d00ff !important;
  }
  .nav-element:hover {
    color: white !important;
  }
  .session-button {
    display: block !important;
  }

  .profile {
    margin-right: 20%;
    display: block !important;
    justify-content: flex-end;
  }
  .brand-items {
    display: inline-flex;
    align-items: center;
    width: 100%;
  }
  .hide-inPc {
    display: block;
  }
  .hide-inMobile {
    display: none;
  }
  .navbar-toggler-icon {
    display: block !important;
  }
  .menu-icon {
    color: #5d00ff;
    font-size: 25pt !important;
    padding: 30%;
    align-items: center;
    align-self: center;
    display: inline-flex;
    justify-content: center;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  }
  .transitionRotateIn {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    transition: all 0.8s ease-in-out;
    transform: rotate(0deg);
  }
  .transitionRotateOut {
    align-items: center;
    align-self: center;
    display: inline-flex;
    transition: ease-in 0.3s;
    transform: scale3d(120%, 150%, 120%);
  }
  .transitionTranslateY {
    margin: 1%;
    transform: skewY(10%);
    transition: all 0.8s ease-in-out;
  }
  .mini-icon {
    width: 1px;
    height: 1px;
  }

  .transitionFadeIn {
    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;
  }
}
