@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color: #5d00ff !important;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 50%,
    rgba(9, 9, 121, 1) 0%,
    #5d00ff 50%
  );

  font-family: "Quicksand", sans-serif;
}
.b-none {
  border: none !important;
}
.logo {
  width: 100px;
  height: 100px;
}
.logo-title a {
  position: inherit;
  font-size: 12pt !important;
  text-align: center;
}
li a:hover {
  color: rgb(240, 17, 54);
  transition: ease-in-out 0.2s;
}
.logo-title:hover a {
  color: rgb(240, 17, 54);
  transition: ease-in-out 0.3s;
  font-size: 16pt;
}
.box {
  height: 600px;
  width: 100%;
  align-items: center;
}
.libro {
  width: 30%;
  height: 30%;
}
.text-wrap {
  word-wrap: break-word I !important;
}
.img-p {
  width: 200px;
  height: 200px;
}
.img-p-large {
  width: 240px;
  height: 200px;
}
.img-large {
  width: 400px;
  height: 200px;
}
.background {
  height: 400px;
  width: 100%;
  background: (90deg, red, #5d00ff);
  align-items: flex-start;
  z-index: -1;
}
.profile-img {
  align-items: flex-end;
  display: flex;
  width: 150px;
  align-items: center;
  justify-content: center;
  height: 150px;
  opacity: 1;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.align-center {
  align-items: center;
  justify-content: center;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bg-primary {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 50%,
    rgba(9, 9, 121, 1) 0%,
    #5d00ff 50%
  );
}

.link-p {
  font-size: 12pt !important;
}
.btn-primary {
  background-color: #ffffff !important;
  color: #5d00ff !important;
}
.btn-outline-primary {
  color: #5d00ff !important;
  border-color: #5d00ff !important;
}
.title {
  padding-top: 10%;
}
h1 {
  font-size: 25pt !important;
  letter-spacing: 4pt;
}
h2 {
  font-size: 22pt !important;
}
h3 {
  font-size: 20pt !important;
}
h5 {
  font-size: 16pt !important;
}
p {
  font-size: 12pt !important;
}
a {
  font-size: 10pt !important;
}
.card-width {
  width: 100%;
}
.gh-icon {
  width: 30px !important;
  height: 30px !important;
}
@media screen and (max-width: 900px) {
  .text-presentation {
    text-align: start;
  }
  .background {
    height: fit-content;
  }
  .full-mobile {
    width: 100% !important;
  }
}

img {
  image-rendering: optimizeSpeed;
}
@media screen and ((max-width: 360dpi) and (max-height: 740dpi)) {
  .text-presentation {
    text-align: center !important;
  }
  .profile-img {
    align-items: center;
  }
  .full-mobile {
    width: 100% !important;
  }
}
@media screen and (max-width: 280px) {
  .text-presentation {
    width: 100%;
    text-align: center !important;
  }
  .full-mobile {
    width: 100% !important;
  }
  h1 {
    font-size: 20pt !important;
    letter-spacing: 4pt;
  }
  h2 {
    font-size: 18pt !important;
  }
  h3 {
    font-size: 16pt !important;
  }
  h5 {
    font-size: 14pt !important;
  }
  p {
    font-size: 12pt !important;
  }
  a {
    font-size: 10pt !important;
  }
}
@media screen and (max-width: 320px) {
  .text-presentation {
    width: 100%;
    text-align: center !important;
  }
  .full-mobile {
    width: 100% !important;
  }
  h1 {
    font-size: 20pt !important;
    letter-spacing: 4pt;
  }
  h2 {
    font-size: 18pt !important;
  }
  h3 {
    font-size: 16pt !important;
  }
  h5 {
    font-size: 14pt !important;
  }
  p {
    font-size: 12pt !important;
  }
  a {
    font-size: 10pt !important;
  }
}
